import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "gatsby-plugin-react-i18next";
import {Section} from "../../common/section";
import GatsbyImage from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";

export const Products: React.FC = () => {
  const {t} = useTranslation();
  const items = [1, 2, 3, 4, 5];
  const data = useStaticQuery(graphql`
  query {
  image1: file(relativePath: {eq: "skeptron/Skeptron-Hybrid-normal.png"}) { ...squareImage }
  image2: file(relativePath: {eq: "skeptron/Skeptron-T-normal.png"}) { ...squareImage }
  image3: file(relativePath: {eq: "skeptron/Skeptron-L-normal.jpg"}) { ...squareImage }
  image4: file(relativePath: {eq: "skeptron/Skeptron-Car-normal.png"}) { ...squareImage }
  image5: file(relativePath: {eq: "skeptron/Skeptron-Pro-normal.png"}) { ...squareImage }
  }`)

  return (
    <Section>
      <h2>{t('products.skeptron.heading')}</h2>
      <ul className="products-list">
        {
          items.map((item, index) => {

            return (
              <li key={index}>
                <div className="product-container">
                  <GatsbyImage
                    style={{height: "100%", width: "100%"}}
                    fluid={data[`image${index + 1}`].childImageSharp.fluid}
                  />
                  <div className="product-info">
                    <h3>{t(`products.skeptron.items.${index}.heading`)}</h3>
                    <p className="small">{t(`products.skeptron.items.${index}.description`)}</p>
                    <Link className="button"
                          to={t(`products.skeptron.items.${index}.link`)}>{t(`common.button.more`)}</Link>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </Section>
  )
}
