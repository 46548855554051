import React from "react";

export const Badges = () => {

  return (
    <ul className="badges">
      <li className="uvc">Λυχνία UVC υψηλής ροής με τον υψηλότερο βαθμό απόδοσης της αγοράς.</li>
      <li className="disinfection">99.9% αποστείρωση όλων των επιφανειών.</li>
      <li className="covid">Άμεση εξουδετέρωση pseudomonas , klebsiella, acinetobacter VRE,MRS, Clostridioides difficile COVID-19.</li>
    </ul>
  )
}