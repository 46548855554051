import React from "react";

export const Section: React.FC<JSX.IntrinsicElements['section']> = (
  {children, ...props}
) => {

  return (
    <section
      {...props}
    >
      <div className="section-content">
        {children}
      </div>
    </section>
  )
}